import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { Link } from 'gatsby'
import Helmet from 'react-helmet';

import {StripeProvider} from 'react-stripe-elements';
import {Stripe} from 'stripe.js'
import Checkout from '../components/checkout';

import Devstar from '../pics/be_a_devstar.jpg'

import Contacts from '../components/contactform';

import Instalments from '../components/instalments.js'


const JSprogramming = () => {

	let [courses, setCourses] = useState({
		durationdays: null,
		durationweeks: 2,
		fulldesc1: "2-week course, in-person, in English, full-time, Monday–Friday, 9:00 to 18:00",
		fulldesc2: "Registration payment 1200€",
		fullprice: 1200,
		image: "https://barcelonacodeschool.com/programming-with-javascript/",
		priceoptions: [],
		shortdesc: "Learn the programming language of the web",
		signupprice: 1200,
		startdates: ['August 26, 2024', 'October 28, 2024','January 13, 2025', 'March 24, 2024','June 2, 2025'],
		starttime: "9:00",
		tag: "jsProg",
		title: "Programming with JavaScript",
	})

	// useEffect(()=>{
	// 	let getData = async () => {
	// 		let data = await axios.get('https://barcelonacodeschool.com/events/jsProg')
	// 		setCourses(data.data)
	// 		console.log(data.data)
	// 	}
	// 	getData()
	// },[])

	let renderCourses =()=> {
		return courses.startdates.map((date,i)=>(
			<div key={i}className="whiteCard bottomSpace flex300" >
			<span>
			<h2>{date}</h2>
			<h4>{courses.title}</h4>
			<p>{courses.shortdesc}</p>
			</span>
			<span>
			<p>{courses.fulldesc1}</p>
			<p>{courses.fulldesc2}</p>
			{date.includes("March 18, 2024")&& <div className='highlighter'><h2>Join the waitlist</h2><p className='finePrint' style={{display:'block'}}>If one of the places will become available you will be notified straight away.</p> <p className='finePrint' style={{display:'block'}}>If not, you will get a full refund.</p></div>}
			{date.includes("March 18, 2024")?<Link to="/register" state={{course: courses.title, date:date, cost:courses.signupprice, images:[courses.image]}}>
			<button style={{'marginBottom': '1em'}}>Join the waitlist</button>
			</Link>:<Link to="/register" state={{course: courses.title, date:date, cost:courses.signupprice, images:[courses.image]}}>
			<button style={{'marginBottom': '1em'}}>SIgn up</button>
			</Link>}
			<p className='finePrint'>By signing up you agree to the <a href='/about-us/terms-and-conditions/'>Terms and Conditions</a> stated on the website.</p>
			</span>
			</div>
			))
	}


	return <div className="flexContent"
	>
	<Helmet

	title="Programming with JavaScript course in Barcelona Code School"
	meta={[
		{ name: 'description', content: 'Programming with JavaScript is a 2-week intensive programming course from Barcelona Code School. Fast-paced practical training aimed at beginners which will take them to the advanced level and give a solid understanding of logic, syntax and usage of JavaScript. This course is a part of the JavaScript Full-Stack Bootcamp and could be taken separately as a module.' },
		{ name: 'keywords', content: 'programming with javascript course, javascript course, javascript programming in barcelona' },
		{ name: 'viewport', content: 'width=device-width, initial-scale=1' }
		]}
	script={[
		{ "src": "https://js.stripe.com/v3/" }
		]}
	/>

	<h1 className='transparentContainer flex900'>Programming with JavaScript course in Barcelona Code School</h1>

	<img 
	className='banners flex900 offsetImage'
	src={Devstar} 
	alt='JavaScript programming course in Barcelona Code School'/>


	<h2 className='transparentContainer flex450'>Learn logic, syntax and usage of JavaScript!</h2>




	<div className='transparentContainer flex300'>
	<article className='whiteCard'>
	<ul className='neonList'>
	<li>Intensive in-person hands-on training</li>
	<li>2 weeks of pure JavaScript coding</li>
	<li>Daily classes 9:00 to 18:00</li>
	<li>The course is taught in English</li>
	<li>From beginner to advanced level</li>
	<li>Learn the 'language of the web' in one go</li>
	</ul>
	</article>
	</div>



	<article className="transparentContainer flex450">
	<h2>What you'll learn</h2>
	<div className='whiteCard'>
	<ul>
	<li>Programming logic</li>
	<li>Variables</li>
	<li>Comparison operators</li>
	<li>Built-in methods</li>
	<li>Data types</li>
	<li>Arrays</li>
	<li>Strings</li>
	<li>Loops</li>
	<li>Conditionals</li>
	<li>Functions</li>
	<li>Objects</li>
	<li>ES6 syntax, recursion, scope</li>
	<li>Classes, prototypes, inheritance</li>
	<li>Constructors</li>
	<li>Array methods</li>
	<li>Practicing advanced JavaScript</li>
	</ul>
	</div>
	</article>



	<article className='transparentContainer flex225'>
	<h2>The outcomes</h2>
	<div className='whiteCard'>

	<p>After this course you will have a strong understanding of the <strong>logic</strong>, <strong>syntax</strong> and <strong>usage</strong> of JavaScript. </p>
	<p>You will be ready to move on to add these new skills to your web applications and start learning any JS library, should it be React.js, Angular, Vue, Ember or anything else.</p>
	<p>Ability to solve programming challenges with JS by splitting them into logical steps and using algorithms for finding the best solution.</p>
	</div>
	</article>

	<article className='transparentContainer flex225'>
	<h2>Tuition</h2>
	<div className='whiteCard'>
	<h2>1200€</h2>
	<p>100% is paid upon registration.</p>
	<p>After that you get the pre-course materials right away and can start working on them. They include some beginner-friendly introduction to the basic JS concepts to get familiar with them and get the proper mindset for the course.</p>
	<Instalments/>
	</div>
	</article>


	<article id="cohorts" className="transparentContainer flex1200 flexContent">
	<h2>Access</h2>
	<div className='flexContent'>
	{renderCourses()}
	</div>
	</article>


	<div className='flex450'>
	<Contacts title={'Inquiry about the course'} subject={'Inquiry about Programming with JS'} />
	</div>
	</div>

}

export default JSprogramming
